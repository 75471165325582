<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1 style="text-transform: uppercase">{{ pageTitle }}</h1>
        <div class="d-flex align-items-center mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >Die in dieser Tabelle hinterlegten Artikelpreise dienen als
            Grundlage für die automatische Preiskalkulation.</span
          >
        </div>
        <h3 class="pt-12" style="text-transform: uppercase">
          Kunde: {{ company_client.CompanyName }}
        </h3>
        <!--end::Info-->

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
          ></loading>
        </div>

        <v-app>
          <v-container>
            <v-row style="margin-top: 25px;">
              <v-col cols="12" class="d-flex justify-end text-center">
                <v-btn
                  class="primary custom_button"
                  large
                  height="50"
                  style="margin-right: 15px;"
                  @click="dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Neuer Artikel
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  sort-by="CompanyName"
                  class="table1"
                  :loading="loadingvariable"
                  loading-text="Bitte warten... Artikel werden geladen"
                >
                  <template v-slot:footer.page-text="items">
                    {{ items.pageStart }} von {{ items.itemsLength }}
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ getFormatedPrice(item.price) }}
                  </template>
                  <template v-slot:item.name="{ item }">
                    {{ item.items[0] && item.items[0].name }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      title="Editiere Artikel"
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <!--                    <v-icon
                                          title="Copy"
                                          small
                                          class="mr-2"
                                          @click="copyItem(item)"
                                        >
                                          mdi-content-copy
                                        </v-icon>-->
                    <v-icon
                      title="Lösche Artikel"
                      small
                      class="mr-2"
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    Keine KUNDE/ARTIKEL- Preise vorhanden
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-dialog v-model="dialog" max-width="600px">
              <!--              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                  Create new client
                </v-btn>
              </template>-->
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.external_item_id"
                            label="Externe Artikelnummer des Kunden*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="editedItem.item_id"
                            :items="internal_items"
                            item-text="name"
                            item-value="id"
                            :rules="[v => !!v || 'Pflichtfeld']"
                            label="iTex Artikel auswählen*"
                            filled
                            dense
                            clearable
                            required
                          >
                            <template slot="item" slot-scope="data">
                              {{
                                data.item.internal_number +
                                  " - " +
                                  data.item.name +
                                  " - " +
                                  getFormatedPrice(data.item.price)
                              }}
                              <!-- Fügen Sie hier weitere Attribute hinzu, wie z.B. {{ item.attribute1 }}, {{ item.attribute2 }}, usw. -->
                            </template>
                            <template slot="selection" slot-scope="data">
                              {{
                                data.item.internal_number +
                                  " - " +
                                  data.item.name +
                                  " - " +
                                  getFormatedPrice(data.item.price)
                              }}
                              <!-- Fügen Sie hier weitere Attribute hinzu, wie z.B. {{ item.attribute1 }}, {{ item.attribute2 }}, usw. -->
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-currency-field
                            v-model="editedItem.price"
                            label="Preis*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-currency-field>
                        </v-col>
                      </v-row>
                      <div v-if="formInvalid" class="fillAllFieldsMessage">
                        {{ formInvalidMessage }}
                      </div>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="secondary"
                    color="primary darken-1"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn class="primary" text @click="save">
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-app>
      </div>
    </div>
  </div>
</template>
<style>
.v-data-footer__select {
  display: none !important;
}
.custom_button.theme--light.v-btn.v-btn--disabled {
  background-color: lightgray !important;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// eslint-disable-next-line no-unused-vars
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Vue from "vue";
Vue.use(Loading);

export default {
  name: "items",
  components: { Loading },
  data: function() {
    return {
      pageTitle: "Kunde/Artikel- Preisverwaltung",
      dialog: false,
      loadingvariable: false,
      headers: [
        { text: "Extern Artikel", value: "external_item_id" },
        { text: "Intern Artikel", value: "name" },
        { text: "Preis", value: "price" },
        { text: "Optionen", value: "actions", sortable: false }
      ],
      totalItems: 0,
      options: { page: 1 },
      items: {},
      editedIndex: -1,
      editedItem: {
        id: "",
        external_item_id: "",
        item_id: "",
        price: "",
        client_id: null
      },
      unit_item: null,
      formInvalidMessage: "*Bitte beachten Sie die Pflichtfelder",
      formInvalid: false,
      rules: {
        fullPage: false,
        required: value => !!value || "Pflichtfeld",
        formHasErrors: false,
        min: v => (v && v.length >= 8) || "Min. 8 Zeichen",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      internal_items: [],
      company_client: null,
      client_id: null,
      isLoading: false
    };
  },
  created() {
    this.items = [];

    if (!this.$route.params.client_id) {
      this.$router.push({ name: "clients" });
    }

    this.client_id = this.$route.params.client_id;
    this.company_client = this.$route.params.company_client;
    this.company_client_name = this.company_client.CompanyName;

    this.getDataFromApi(this.client_id);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Overview" }]);
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Artikel" : "Editiere Artikel";
    }/*,
    price: {
      get: function() {
        if (parseFloat(this.editedItem.price) > 0) {
          return this.editedItem.price / 1000;
        }

        return this.editedItem.price;
      },
      set: function(newValue) {
        if (parseFloat(newValue) > 0) {
          //this.editedItem.price = Math.round(newValue * 100);
          this.editedItem.price = newValue * 1000;
        } else {
          this.editedItem.price = newValue;
        }
      }
    }*/
  },
  watch: {
    options: {
      handler() {
        //this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    initializePrice() {
      if (parseFloat(this.editedItem.price) > 0) {
        this.editedItem.price = this.editedItem.price / 1000;
      }
    },
    getDataFromApi(client_id) {
      this.loadingvariable = true;
      ApiService.query("/items/all")
        .then(({ data }) => {
          this.loadingvariable = false;
          this.internal_items = data.items;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            if (response.status === 401) {
              this.loadingvariable = false;
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });

      ApiService.query("/clients/items/" + client_id)
        .then(({ data }) => {
          this.loadingvariable = false;
          this.items = data.items;
          this.totalItems = data.items.total;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            if (response.status === 401) {
              this.loadingvariable = false;
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });
    },
    deleteItem(item) {
      Swal.fire({
        title: "Artikel löschen",
        text: "Möchten Sie diesen Artikel wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Löschen",
        cancelButtonText: "Abbrechen"
      }).then(invoice => {
        if (invoice.value) {
          this.isLoading = true;
          ApiService.delete("/clients/items/" + item.id)
            .then(() => {
              location.reload();
            })
            .catch(({ response }) => {
              if (response.status === 401) {
                if (response.status === 401) {
                  this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({ name: "login" }));
                }
              }
              this.isLoading = false;
            });
        }
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;

      this.$nextTick(() => {
        // Rufe initializePrice auf, wenn ein Element bearbeitet wird
        this.initializePrice();
      });
    },
    copyItem(item) {
      //this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    getFormatedPrice(price) {
      if (parseFloat(price) > 0) {
        return price / 1000;
      }

      return price + " €";
    },
    setFormatedPrice(price) {
      if (parseFloat(price) > 0) {
        price = Math.round(price * 1000);
      }
    },
    save() {
      this.editedItem.client_id = this.client_id;
      if (this.$refs.form.validate()) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        });
        if (parseFloat(this.editedItem.price) > 0) {
          this.editedItem.price = this.editedItem.price * 1000;
        } else {
          this.editedItem.price = this.editedItem.price * 1000;
        }
        if (this.editedIndex > -1) {
          if (this.editedItem.hasOwnProperty("itemsunit"))
            delete this.editedItem["itemsunit"];
          ApiService.put("clients/items/update", this.editedItem)
            .then(response => {
              let item = response.data.items;
              if (item) {
                Object.assign(this.items[this.editedIndex], item);
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(response => {
              this.formInvalidMessage = response.data.message;
              this.formInvalid = true;
              loader.hide();
            });
        } else {
          ApiService.post("clients/items/add", this.editedItem)
            .then(({ data }) => {
              if (data.items) {
                this.items.splice(0, 0, data.items);
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(response => {
              this.formInvalidMessage = response.data;
              this.formInvalid = true;
              loader.hide();
            });
        }
      } else this.formInvalid = true;
    },
    resetForm() {
      this.formInvalid = false;
      for (var key in this.editedItem) {
        this.editedItem[key] = "";
      }
    },
    getHeaders(headingText) {
      return [
        {
          text: "Dynamic heading no. " + headingText,
          align: "left",
          sortable: false,
          value: "name"
        }
      ];
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
